import HeroContainer from "../common/components/HeroContainer"
import Layout from "../layout/components/Layout"
import React from "react"
import SEO from "../layout/components/SEO"
import { StyledHeroTitle } from "../common/components/styled/hero"
import { documentToComponents } from "../articles/utils"
import { graphql } from "gatsby"
import logo from "../images/logo_colour_background.svg"

function About({ data }) {
  const {
    contentfulAbout: { seo, body },
  } = data

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} />

      <HeroContainer>
        <StyledHeroTitle>About</StyledHeroTitle>
      </HeroContainer>
      <div className="w-full lg:w-3/5 mx-auto md:mt-10 mb-12 shadow shadow-lg no-underline bg-white px-6 py-12">
        <div className="flex justify-center items-center mb-12">
          <img
            src={logo}
            alt="WhatsMine Logo"
            style={{ width: 100, height: 100 }}
            className="rounded-full shadow-lg"
          />
        </div>
        <div className="font-light">{documentToComponents(body)}</div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query aboutQuery {
    contentfulAbout {
      title
      logo {
        fluid(quality: 100, maxWidth: 150, maxHeight: 150) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        json
      }
      seo {
        title
        description
      }
    }
  }
`

export default About
